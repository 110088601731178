
import { Watch, Mixins, Component, ProvideReactive } from "vue-property-decorator";

import HealthEvaluationPaper from "./components/paperSetting.vue";
import HealthEvaluationReport from "./components/reportSetting.vue";

@Component({
  inject: [],
  components: {HealthEvaluationPaper, HealthEvaluationReport}
})
export default class HealthEvaluation extends Mixins() {
    @ProvideReactive() currentTab = "1";
}

