
import { Watch, Mixins, Component, InjectReactive } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import { healthExamineList } from "@/api/healthEvaluation";


@Component({
  components: {ETable}
})
export default class HealthEvaluationPaper extends Mixins(loading, tablePagination, Resize) {
    // loading = false;
    itemTotal = 0;
    tableData = [];

    @InjectReactive() currentTab !: number;

    columns = [
        { label: "ID", prop: "id" },
        { label: "测评名称", prop: "examName" },
        { label: "外显名称", prop: "showName" },
        { label: "封面", prop: "icon" },
        { label: "题目总数", prop: "total" },
        { label: "排序", prop: "sort" },
        { label: "已测次数", prop: "completeNum" },
        { label: "状态", prop: "statusDesc" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action" },
    ]

    mounted() {
      this.windowResize(320);
      this.getData();
    }

    @Watch("currentTab")
    getData() {
      if (this.currentTab != 1) return;
      const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
      }
      this.showLoading();
      healthExamineList(params).then((res) => {
        this.tableData = res.data.list;
        this.tableData.forEach((el) => {
          el.statusDesc = el.status ? "启用" : "禁用";
        })
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      })
    }

    addExamine() {
      this.$router.push({
        path: "/healthEvaluationFill"
      })
    }

    edit(row) {
      this.$router.push({
        path: "/healthEvaluationFill",
        query: { id: row.id, step: row.step }
      })
    }
}

